/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const mock = [
  {
    
    description:
      'Science Art',
    href : '/science-art-2023'
 
  },
  {
    
    description:
      'Song Spoof',
    href : '/song-spoof-2023'
 
  },
  {
    
    description:
      'Picture Talk & Model Making',
    href : '/picture-talk-2023'
 
  },
  {
    
    description:
      'General Science CCA - Class 3 to 5',
    href : '/general-science-2023'
 
  },
  {
    
    description:
      'Planetarium Show - Class 3 and 4',
    href : '/planetariumshow2023'
 
  },
 
  // {
  //   image: 'https://assets.maccarianagency.com/backgrounds/img16.jpg',
  //   description:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  //   title: 'Consectetur adipiscing elit',
  //   author: {
  //     name: 'Jhon Anderson',
  //   },
  //   date: '12 Sep',
  // },
];

const Scienceclubsidebar2023 = () => {
  
  return (
    <Box component={Card} variant={'outlined'} padding={2}>
      
      <Typography
        variant="h6"
        data-aos={'fade-up'}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        Science Club 2023-24
      </Typography>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              boxShadow={0}
              borderRadius={0}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Button href = {item.href}>
                <Typography component={'h6'}>
                  {item.description}
                </Typography>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Scienceclubsidebar2023;
